<template>
    <div>
        <b-row>
            <!-- Name -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-name-fg" label="Name:" label-for="contact-name-input">
                    <b-form-input id="contact-name-input" v-model="contact.name" trim />
                </b-form-group>
            </b-col>
            <!-- Company -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-company-fg" label="Firma:" label-for="contact-company-input">
                    <b-form-input id="contact-company-input" v-model="contact.company" trim />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <!-- Address -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-address-fg" label="Adresse:" label-for="contact-address-input">
                    <b-form-input id="contact-address-input" v-model="contact.address" trim disabled />
                </b-form-group>
            </b-col>
            <!-- City -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-city-fg" label="Ort:" label-for="contact-city-input">
                    <b-form-input id="contact-city-input" v-model="contact.city" trim disabled />
                </b-form-group>
            </b-col>
            <!-- PostalCode -->
            <b-col cols="12" sm="6" md="3">
                <b-form-group id="contact-postalCode-fg" label="PLZ:" label-for="contact-postalCode-input">
                    <b-form-input id="contact-postalCode-input" v-model="contact.postalCode" trim disabled />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <!-- Contact Info -->
            <b-card title="Kontaktmethoden" class="w-100">
                <b-card-body>
                    <b-row
                        v-for="contactInfo in uniqueFilteredContactInfo(contact.contactInfos)"
                        :key="contactInfo.contactInfoID"
                        align-v="center"
                    >
                        <!-- Type -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-type-fg" label="Typ:" label-for="contact-type-input">
                                <b-select
                                    id="contact-type-input"
                                    v-model="contactInfo.type"
                                    required
                                    :options="contactInfoTypes"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- Value -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-value-fg" label="Wert:" label-for="contact-value-input">
                                <b-form-input id="contact-value-input" v-model="contactInfo.value" trim required />
                            </b-form-group>
                        </b-col>
                        <!-- Note -->
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group id="contact-note-fg" label="Anmerkung:" label-for="contact-note-input">
                                <b-form-input id="contact-note-input" v-model="contactInfo.note" trim />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-alert v-if="!contact.contactInfos || contact.contactInfos.length === 0" show>
                                Keine Kontaktinformationen
                            </b-alert>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-row>
        <b-alert v-if="multipleContactsWarning" dismissible>{{ multipleContactsWarning }}</b-alert>
    </div>
</template>

<script>
import contactsAPI from "@/services/api/contacts.api";

export default {
    name: "Contacts",
    components: {},
    props: {
        entity: {
            type: String,
            required: true,
        },
        entityID: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            contact: {
                contactID: null,
                name: null,
                company: null,
                address: null,
                city: null,
                postalCode: null,
                phone: null,
                email: null,
            },
            contactInfos: [],
            multipleContactsWarning: null,
            contactInfoTypes: [
                { value: "Telefon", text: "Telefon" },
                { value: "E-Mail", text: "E-Mail" },
                { value: "Mobil", text: "Mobil" },
            ],
        };
    },
    computed: {
        contactExists() {
            return this.contact.contactID != null;
        },
    },
    async mounted() {
        // ToDo: remove awaits
        await this.loadContacts();
    },
    methods: {
        async loadContacts() {
            try {
                const contacts = await contactsAPI.get({
                    parentTable: this.entity,
                    parentID: this.entityID,
                });
                if (contacts.length > 0) {
                    this.contact = contacts[0];
                }
                if (contacts.length > 1) {
                    this.multipleContactsWarning = "Multiple contacts related to this HAK";
                }
            } catch (err) {
                //console.error(err);
                return null; // TODO: manage errors
            }
        },
        // Sort out duplicates and Telefax and Internet/WWW #20527
        uniqueFilteredContactInfo(contactInfo) {
            if (!contactInfo || contactInfo.length === 0) {
                return [];
            }
            const seenValues = new Set();
            return contactInfo
                .filter((ci) => ci.type !== "Telefax" && ci.type !== "Internet/WWW")
                .filter((ci) => {
                    if (seenValues.has(ci.value)) {
                        return false;
                    }
                    seenValues.add(ci.value);
                    return true;
                });
        },
    },
};
</script>
