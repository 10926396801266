import constants from "@/constants/constants";
import { httpClient } from "@/services/api/client";
import axios from "axios";

const endpoint = "/assets";
const portalApiConfig = {
    baseURL: constants.env.API_PORTAL_URL,
};

export default {
    // ********************
    // default REST methods
    // ********************
    delete(id, physicalDelete = false) {
        return httpClient.delete(`${endpoint}/${id}?physical=${physicalDelete}`, portalApiConfig);
    },
    post(payload) {
        return httpClient.post(endpoint, payload);
    },
    patch(id, patchDoc) {
        return httpClient.patch(`${endpoint}/${id}`, patchDoc);
    },
    uploadImage(id, formData, signal) {
        const config = this.getConfig(signal);
        return httpClient.post(`${endpoint}/upload/?id=${id}`, formData, config);
    },
    downloadAsset(id, payload) {
        return httpClient.get(`${endpoint}/download/${id}`, payload);
    },
    previewAsset(id, payload) {
        return httpClient.get(`${endpoint}/preview/${id}`, payload);
    },
    get(params) {
        return httpClient.get(endpoint, { params: params });
    },
    getSingle(id, params) {
        return httpClient.get(`${endpoint}/${id}`, { params: params });
    },
    // ********************
    // custom methods
    // ********************
    getConfig(signal) {
        return {
            ...portalApiConfig,
            headers: {
                "content-type": "multipart/form-data",
            },
            signal: signal || undefined,
        };
    },
    transformBase64ToArrayBuffer(base64String) {
        return axios.get(base64String, { responseType: "arraybuffer" });
    },
};
