<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!mainDevice" />
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="mainDevice"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Wandler'"
                        :showRelatedDeviceInfo="true"
                    />
                    <div>
                        <span
                            >Bitte erfassen Sie die folgenden Daten zur Berechnung des Faktors. Der Faktor wird
                            automatisch in MSP geschrieben.
                        </span>
                    </div>
                    <b-form class="mt-2">
                        <b-row class="align-items-end">
                            <b-col cols="7">
                                <b-form-group label="Verhältnis Stromwandler" class="mb-1">
                                    <b-row>
                                        <b-col cols="5">
                                            <b-form-input
                                                v-model="form732.verhaeltnisStromwandler.numerator"
                                                type="number"
                                                :disabled="!actAuftragDetail.isExecutable"
                                            />
                                        </b-col>
                                        <b-col cols="1"> / </b-col>
                                        <b-col cols="5">
                                            <b-form-input
                                                v-model="form732.verhaeltnisStromwandler.denominator"
                                                type="number"
                                                :disabled="!actAuftragDetail.isExecutable"
                                            /> </b-col
                                    ></b-row>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label="Faktor" class="mb-1">
                                    <b-form-input
                                        v-model="form732.verhaeltnisStromwandler.factor"
                                        disabled
                                        :state="
                                            isInputFormValid(
                                                form732.verhaeltnisStromwandler.factor,
                                                'verhaeltnisStromwandlerFactor'
                                            )
                                        "
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="1" class="mb-2 pl-0">
                                <font-awesome-icon
                                    :icon="['fas', 'exclamation-circle']"
                                    v-on:click="onClickMoreInfo(verhaeltnisStromwandlerInfo)"
                                    size="lg"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-invalid-feedback
                                    class="mb-2"
                                    :state="
                                        isInputFormValid(
                                            form732.verhaeltnisStromwandler.factor,
                                            'verhaeltnisStromwandlerFactor'
                                        )
                                    "
                                >
                                    {{ validationErrors.verhaeltnisStromwandlerFactor }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-end">
                            <b-col cols="7">
                                <b-form-group label="Verhältnis Zähler-Parametrierung Strom" class=" mb-1">
                                    <b-row>
                                        <b-col cols="5">
                                            <b-form-input
                                                v-model="form732.verhaeltnisZaehlerParametrierungStrom.numerator"
                                                type="number"
                                                :disabled="
                                                    !form732.isDeviatingRatioChecked || !actAuftragDetail.isExecutable
                                                "
                                            />
                                        </b-col>
                                        <b-col cols="1"> / </b-col>
                                        <b-col cols="5">
                                            <b-form-input
                                                v-model="form732.verhaeltnisZaehlerParametrierungStrom.denominator"
                                                type="number"
                                                :disabled="
                                                    !form732.isDeviatingRatioChecked || !actAuftragDetail.isExecutable
                                                "
                                            /> </b-col
                                    ></b-row>
                                </b-form-group>
                            </b-col>
                            <b-col cols="4">
                                <b-form-group label="Faktor" class=" mb-1">
                                    <b-form-input
                                        disabled
                                        v-model="form732.verhaeltnisZaehlerParametrierungStrom.factor"
                                        :state="
                                            isInputFormValid(
                                                form732.verhaeltnisZaehlerParametrierungStrom.factor,
                                                'verhaeltnisZaehlerParametrierungStromFactor'
                                            )
                                        "
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="1" class=" mb-2 pl-0">
                                <font-awesome-icon
                                    :icon="['fas', 'exclamation-circle']"
                                    v-on:click="onClickMoreInfo(verhaeltnisZaehlerParametrierungStromInfo)"
                                    size="lg"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-invalid-feedback
                                    class="mb-2"
                                    :state="
                                        isInputFormValid(
                                            form732.verhaeltnisZaehlerParametrierungStrom.factor,
                                            'verhaeltnisZaehlerParametrierungStromFactor'
                                        )
                                    "
                                >
                                    {{ validationErrors.verhaeltnisZaehlerParametrierungStromFactor }}
                                </b-form-invalid-feedback>
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center mb-3">
                            <b-col cols="12">
                                <b-form-checkbox
                                    v-model="form732.isDeviatingRatioChecked"
                                    :disabled="!actAuftragDetail.isExecutable"
                                    >abweichendes Verhältnis</b-form-checkbox
                                >
                            </b-col>
                        </b-row>
                        <b-row class="align-items-center">
                            <b-col cols="12">
                                <b-form-group label="Resultierender Gesamtfaktor für MSP">
                                    <b-row>
                                        <b-col cols="5">
                                            <b-form-input disabled v-model="form732.verhaeltnisMsp.numerator" />
                                        </b-col>
                                        <b-col cols="1"> / </b-col>
                                        <b-col cols="5">
                                            <b-form-input
                                                disabled
                                                v-model="form732.verhaeltnisMsp.denominator"
                                            /> </b-col
                                    ></b-row>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </div>
            <ExecSectionErledigt
                :isBusy="compIsBusy"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import devicesAPI from "@/services/api/devices.api";
import zfaAPI from "@/services/api/zfa.api";
import mspApi from "@/services/api/msp.api";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo.vue";
import constants from "@/constants/constants";

export default {
    name: "Step_7_3_2",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecSectionErledigt,
        ExecMeterInfo,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            device: null,
            form732: {
                verhaeltnisStromwandler: { numerator: null, denominator: null, factor: null },
                verhaeltnisZaehlerParametrierungStrom: { numerator: 100, denominator: 5, factor: 20 },
                verhaeltnisMsp: { numerator: null, denominator: null },
                isDeviatingRatioChecked: false,
            },
            isLoading: false,
            isBusy: false,
            mainDevice: null,
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            validationErrors: {
                verhaeltnisStromwandlerFactor: null,
                verhaeltnisZaehlerParametrierungStromFactor: null,
            },
            verhaeltnisZaehlerParametrierungStromInfo: {
                imageFilename: require("@/assets/zaehlerParametrierungStromVerhaeltnisInfoImage.png"),
                text:
                    "Bitte hier das im Zähler parametrierte Stromwandlerverhältnis eintragen. Dieses ist auf dem Wandlerzähler aufgedruckt. Beispiel Ensor eRS301",
            },
            verhaeltnisStromwandlerInfo: {
                imageFilename: require("@/assets/stromwandlerVerhaeltnisInfoImage.png"),
                text:
                    "Bitte hier das Verhältnis des Stromwandlers eintragen. Dieses ist auf dem Stromwandler aufgeführt. Beispiel:",
            },
            zfaFactors: null,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compIsBusy() {
            return this.isBusy;
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            preconditionsLocalDetails.push(
                this.checkValidationError(
                    this.validationErrors.verhaeltnisStromwandlerFactor,
                    "Der Faktor für das Verhältnis Stromwandler ist nicht ganzzahlig. Bitte die Werte überprüfen.",
                    "Der Faktor für das Verhältnis Stromwandler ist ganzzahlig."
                )
            );

            preconditionsLocalDetails.push(
                this.checkValidationError(
                    this.validationErrors.verhaeltnisZaehlerParametrierungStromFactor,
                    "Der Faktor für das Verhältnis Zähler-Parametrierung Strom ist nicht ganzzahlig. Bitte die Werte überprüfen.",
                    "Der Faktor für das Verhältnis Zähler-Parametrierung Strom ist ganzzahlig."
                )
            );

            return preconditionsLocalDetails;
        },
        compVerhaeltnisStromwandlerFaktor() {
            const { denominator, numerator } = this.form732.verhaeltnisStromwandler;
            return this.calculateFraction(numerator, denominator);
        },
        compVerhaeltnisZaehlerParametrierungStromFaktor() {
            const { denominator, numerator } = this.form732.verhaeltnisZaehlerParametrierungStrom;
            return this.calculateFraction(numerator, denominator);
        },
        compMSPFaktor() {
            let numerator = this.compVerhaeltnisStromwandlerFaktor;
            let denominator = this.compVerhaeltnisZaehlerParametrierungStromFaktor;
            return this.simplifyFraction(numerator, denominator);
        },
        isInputFormValid() {
            return (calculatedFactor, validationErrorField) => {
                if (!calculatedFactor && validationErrorField) {
                    this.validationErrors[validationErrorField] =
                        "Bitte die Felder korrekt ausfüllen. Der Faktor muss ganzahlig sein.";
                    return false;
                }
                this.validationErrors[validationErrorField] = null;
                return true;
            };
        },
    },
    async mounted() {
        this.isLoading = true;

        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];

        const auftragDetails732 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            step,
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId
        );
        await this.setActAuftragDetail(auftragDetails732);
        if (auftragDetails732.value) {
            try {
                const parsedJSON = JSON.parse(auftragDetails732.value);
                this.form732.verhaeltnisStromwandler.numerator =
                    parsedJSON.mspFactors.verhaeltnisStromwandler.numerator;
                this.form732.verhaeltnisStromwandler.denominator =
                    parsedJSON.mspFactors.verhaeltnisStromwandler.denominator;
                this.form732.verhaeltnisStromwandler.factor = parsedJSON.mspFactors.verhaeltnisStromwandler.factor;
                this.form732.verhaeltnisZaehlerParametrierungStrom.numerator =
                    parsedJSON.mspFactors.verhaeltnisZaehlerParametrierungStrom.numerator;
                this.form732.verhaeltnisZaehlerParametrierungStrom.denominator =
                    parsedJSON.mspFactors.verhaeltnisZaehlerParametrierungStrom.denominator;
                this.form732.verhaeltnisZaehlerParametrierungStrom.factor =
                    parsedJSON.mspFactors.verhaeltnisZaehlerParametrierungStrom.factor;
                this.form732.verhaeltnisMsp.numerator = parsedJSON.mspFactors.verhaeltnisMsp.numerator;
                this.form732.verhaeltnisMsp.denominator = parsedJSON.mspFactors.verhaeltnisMsp.denominator;
                this.form732.isDeviatingRatioChecked = parsedJSON.mspFactors.isDeviatingRatioChecked;
            } catch (error) {
                const errorMessage = "Die gespeicherten Daten aus Schritt 7-3-2 konnten nicht geladen werden.";
                this.displayToast("DANGER", errorMessage);
            }
        }
        this.device = await devicesAPI
            .getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })
            .then((resp) => resp.data);

        this.mainDevice = await this.fetchRelatedDeviceDetails();
        if (this.device?.factoryNo === null) {
            this.displayToast("DANGER", "Für dieses Gerät wurde keine Fabriknummer gefunden.");
        }
        const zfaRes = await zfaAPI.getDeviceMasterData(this.device.factoryNo);
        if (zfaRes.data?.status?.toUpperCase() === "FAILED") {
            this.displayToast("INFO", zfaRes.data?.message);
        }
        this.zfaFactors = zfaRes.data?.factors;

        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        calculatedFactorsMatchZfaOrDefault(
            transformerFactorI,
            generalConstant,
            compMSPFaktorNumerator,
            compMSPFaktorDenominator
        ) {
            return transformerFactorI === compMSPFaktorNumerator && generalConstant === compMSPFaktorDenominator;
        },
        calculateFraction(numerator, denominator) {
            if (this.validateNumber(numerator) && this.validateNumber(denominator) && denominator !== 0) {
                const fraction = numerator / denominator;
                return Number.isInteger(fraction) ? fraction : null;
            }
            return null;
        },
        checkValidationError(validationError, openMessage, fulfilledMessage) {
            if (validationError && Object.keys(validationError).length > 0) {
                return {
                    status: "OPEN",
                    message: openMessage,
                };
            } else {
                return {
                    status: "FULFILLED",
                    message: fulfilledMessage,
                };
            }
        },
        ggT(numerator, denominator) {
            if (denominator === 0) {
                return numerator;
            }
            if (!Number.isInteger(numerator) || !Number.isInteger(denominator)) {
                const errorMessage = "Beide Nummern müssen ganzahlig sein.";
                this.displayToast("DANGER", errorMessage);
            }
            return this.ggT(denominator, numerator % denominator);
        },
        onClickNext() {
            this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        onClickErledigt() {
            this.isBusy = true;

            const auftragDetailsStatus = this.determineAuftragDetailsStatus();
            const factoryNo = this.mainDevice?.factoryNo?.replace(/\s+/g, "");

            const mspPayload = {
                numerator: this.form732.verhaeltnisMsp.numerator,
                denominator: this.form732.verhaeltnisMsp.denominator,
            };

            mspApi
                .postWandlerFactorCurrent(factoryNo, mspPayload)
                .catch((error) => {
                    return Promise.reject(error.response?.data?.Message ?? error.response?.data);
                })
                .then(() => {
                    const payloadAuftragDetail = [
                        {
                            op: "replace",
                            path: "/status",
                            value: auftragDetailsStatus,
                        },
                        {
                            op: "replace",
                            path: "/value",
                            value: JSON.stringify({
                                mspFactors: this.form732,
                                zfaFactors: this.zfaFactors,
                            }),
                        },
                    ];
                    if (auftragDetailsStatus !== null) {
                        return auftragDetailsAPI
                            .patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail)
                            .catch((error) => {
                                return Promise.reject(error.response?.data?.Message ?? error.response?.data);
                            });
                    }
                })
                .then(() => {
                    if (auftragDetailsStatus === constants.auftragDetailStatus.DONE_CONDITION_1) {
                        // The factors calculated in this step and the ones received from zfa are the same or equal default values (1/1),
                        // therefore steps 7-3-3 and 7-3-4 are skipped
                        return Promise.all([
                            auftragDetailsAPI.setAuftragDetailSkipped(
                                this.$route.params.orderId,
                                "7-3-3",
                                this.$route.params.devicePoolId,
                                this.actAuftragDetail.deviceID
                            ),
                            auftragDetailsAPI.setAuftragDetailSkipped(
                                this.$route.params.orderId,
                                "7-3-4",
                                this.$route.params.devicePoolId,
                                this.actAuftragDetail.deviceID
                            ),
                        ])
                            .then(() => {
                                this.navigateToStepNext = "7";
                            })
                            .catch((error) => {
                                return Promise.reject(error.response?.data?.Message ?? error.response?.data);
                            });
                    } else {
                        this.navigateToStepNext = this.actAuftragDetail.stepNextTrue;
                    }
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        determineAuftragDetailsStatus() {
            //factors calculated in step and received from zfa are the same or equal default values (1/1) -> 202
            //factors calculated in step and received from zfa are different -> 203
            //factors are not present in zfa-> 204

            if (this.zfaFactors !== null) {
                if (
                    this.calculatedFactorsMatchZfaOrDefault(
                        this.zfaFactors.transformerFactorI,
                        this.zfaFactors.generalConstant,
                        this.compMSPFaktor?.numerator,
                        this.compMSPFaktor?.denominator
                    )
                ) {
                    return constants.auftragDetailStatus.DONE_CONDITION_1;
                } else {
                    return constants.auftragDetailStatus.DONE_CONDITION_2;
                }
            } else {
                //Fallback: comparing the computed factors with the default values (1/1)
                if (
                    this.calculatedFactorsMatchZfaOrDefault(
                        1,
                        1,
                        this.compMSPFaktor?.numerator,
                        this.compMSPFaktor?.denominator
                    )
                ) {
                    return constants.auftragDetailStatus.DONE_CONDITION_1;
                } else {
                    return constants.auftragDetailStatus.DONE_CONDITION_3;
                }
            }
        },
        async fetchRelatedDeviceDetails() {
            const deviceResp = await devicesAPI.getRelatedDevice({
                id: this.actAuftragDetail.deviceID,
                devicePoolId: this.$route.params.devicePoolId,
                auftragId: this.$route.params.orderId,
            });
            //The deviceID associated with the auftragDetails of this step
            //is the same as that of the old meter. We therefore retrieve the
            //information from the new installed meter.
            return deviceResp?.data?.newDevice;
        },
        onClickMoreInfo(infoData) {
            const h = this.$createElement;
            const messageVNode = h("div", { class: ["foobar"] }, [
                h("p", { class: ["text-center"] }, [infoData.text]),
                h("b-img", {
                    props: {
                        src: infoData.imageFilename,
                        thumbnail: true,
                        center: true,
                        fluid: true,
                    },
                }),
            ]);
            this.$bvModal.msgBoxOk([messageVNode], {
                title: "Info",
                size: "sm",
                buttonSize: "sm",
                okVariant: "info",
                okTitle: "Ok",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
            });
        },
        simplifyFraction(numerator, denominator) {
            if (this.validateNumber(numerator) && this.validateNumber(denominator) && denominator !== 0) {
                const gcd = this.ggT(numerator, denominator);
                return { numerator: numerator / gcd, denominator: denominator / gcd };
            } else {
                return { numerator: null, denominator: null };
            }
        },
        validateNumber(value) {
            return !!value;
        },
        displayToast(variant = "INFO", message) {
            let noAutoHide = false;
            let title;
            switch (variant.toUpperCase()) {
                case "DANGER":
                    noAutoHide = true;
                    title = "Fehler";
                    break;
                case "INFO":
                    title = "INFO";
                    break;
                case "SUCCESS":
                    title = "SUCCESS";
                    break;
                default:
                    variant = "info";
                    title = "INFO";
                    break;
            }
            this.$bvToast.toast(`${message}`, {
                title: title,
                variant: variant.toLowerCase(),
                toaster: "b-toaster-bottom-right",
                noAutoHide: noAutoHide,
                appendToast: true,
            });
        },
    },
    watch: {
        isDeviatingRatioChecked(newValue) {
            if (newValue === false) {
                // Reset to default value if isDeviatingRatioChecked is false
                this.form732.verhaeltnisZaehlerParametrierungStrom = { numerator: 100, denominator: 5, factor: 20 };
            }
        },
        compVerhaeltnisStromwandlerFaktor(newValue) {
            this.form732.verhaeltnisStromwandler.factor = newValue;
        },
        compVerhaeltnisZaehlerParametrierungStromFaktor(newValue) {
            this.form732.verhaeltnisZaehlerParametrierungStrom.factor = newValue;
        },
        compMSPFaktor: {
            handler(newValue) {
                this.form732.verhaeltnisMsp = newValue;
            },
            deep: true,
        },
    },
};
</script>
