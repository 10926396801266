<template>
    <div>
        <template>
            <!-- Fernablesung -->
            <div v-if="auftragDetail712 && device.telemeteringType > 0">
                Fernablesung: {{ getFernablesungStatusByDeviceId() }}
                <span v-if="auftragDetail712.status === 203">
                    - {{ moment(zfaObject.readingDate).format("DD.MM.YYYY HH:mm") }}</span
                >
            </div>
            <!-- Manual/Optical reading -->
            <div v-if="auftragDetail714">
                <span
                    >Ablesung vor Ort<template v-if="[202, 203].includes(auftragDetail714.status)">
                        ({{ auftragDetail714.status === 202 ? "Ablesekopf" : "manuell" }})</template
                    >:
                </span>
                <span v-if="[202, 203].includes(auftragDetail714.status)">
                    ja - {{ moment(auftragDetail714.executionTime).format("DD.MM.YYYY HH:mm") }}
                </span>
                <span v-else>
                    nein
                </span>
            </div>
        </template>
        <b-alert
            v-if="type && type === 'uninstall' && !auftragDetail714"
            class="px-2 py-1 mr-2 mb-0"
            variant="danger"
            show
        >
            Keine auftragDetails für Schritt 7.1.4 gefunden.
        </b-alert>
        <b-alert
            v-if="type && type === 'uninstall' && !auftragDetail712"
            class="px-2 py-1 mr-2 mb-0"
            variant="danger"
            show
        >
            Keine auftragDetails für Schritt 7.1.2 gefunden.
        </b-alert>
    </div>
</template>

<script>
import moment from "moment";
import * as zfaUtils from "@/utils/ZfaUtils";
export default {
    name: "ExecMeterReadingInfo",
    props: {
        device: {
            type: Object,
            required: true,
        },
        auftragDetail714: {
            type: Object,
            required: false,
        },
        auftragDetail712: {
            type: Object,
            required: false,
        },
        type: {
            type: String,
            required: false,
            validator(value) {
                return ["uninstall", "install"].includes(value);
            },
        },
    },
    data() {
        return {};
    },
    computed: {
        moment() {
            return moment;
        },
        zfaObject() {
            return zfaUtils.parseAuftragDetailValue(this.auftragDetail712?.value);
        },
    },
    methods: {
        getFernablesungStatusByDeviceId() {
            const status = this.auftragDetail712?.status;
            switch (status) {
                case 203:
                    return "ja";
                case 202:
                    return "gestartet";
                default:
                    return "nein";
            }
        },
    },
};
</script>

<style scoped></style>
