import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import zfaAPI from "@/services/api/zfa.api";
import constants from "@/constants/constants";
import moment from "moment/moment";

export default {
    /**
     * Check ZFA status from step 2-1-1 and update step 7-1-4
     * @param auftragID
     * @param devicePoolID
     * @returns {Promise<void>}
     */
    async checkJobStatusFromStep211ByDevicePool(auftragID, devicePoolID) {
        const auftragDetails712 = await auftragDetailsAPI.getByOrderIdAndStep(
            auftragID,
            "7-1-2",
            devicePoolID,
            null,
            null,
            true
        );
        let response = null;
        for (const auftragDetail712 of auftragDetails712) {
            if (auftragDetail712.status >= 200 && auftragDetail712.status !== 204) {
                response = await this._checkJobStatus(auftragDetail712, devicePoolID, auftragDetail712.deviceID);
            }
        }
        return response;
    },
    /**
     * Check ZFA reading status from ZFA and then update step 7-1-2.
     * 203: read done
     * @param auftragID
     * @param devicePoolID
     * @param deviceID
     * @returns {Promise<void>}
     */
    async checkJobStatusFromStep712ByDevice(auftragID, devicePoolID, deviceID) {
        const auftragDetail712 = await auftragDetailsAPI.getByOrderIdAndStep(
            auftragID,
            "7-1-2",
            devicePoolID,
            deviceID
        );
        let response = null;
        if (auftragDetail712.status >= 200 && auftragDetail712.status !== 204) {
            response = await this._checkJobStatus(auftragDetail712, devicePoolID, deviceID);
        }
        return response;
    },
    async _checkJobStatus(auftragDetail712, deviceID) {
        if (auftragDetail712 && auftragDetail712.value) {
            const step712value = JSON.parse(auftragDetail712.value);
            const jobID = step712value?.jobID;
            let zfaJobStatus = null;
            try {
                zfaJobStatus = (await zfaAPI.get(jobID)).data;
            } catch (error) {
                return null;
            }
            let status;
            // Can be: unavailable, processing, failed,succeeded
            switch (zfaJobStatus.jobStatus) {
                case "succeeded":
                    status = constants.auftragDetailStatus.DONE_CONDITION_2; // done
                    break;
                case "failed":
                case "unavailable":
                    status = constants.auftragDetailStatus.ERROR;
                    break;
                case "processing":
                    status = constants.auftragDetailStatus.DONE_CONDITION_1; // started
                    break;
                default:
                    // should never happend
                    status = constants.auftragDetailStatus.ERROR;
            }
            const patchResponse = await auftragDetailsAPI.patch(auftragDetail712.auftragDetailID, [
                {
                    op: "replace",
                    path: "/status",
                    value: status, // 204 - fernablesung - 2-1-1
                },
                /// Replace jobStatus and date in 7-2-1 auftragDetail.value
                {
                    op: "replace",
                    path: "/value",
                    value: JSON.stringify({
                        ...step712value,
                        checkJobStatus: {
                            jobStatus: zfaJobStatus.jobStatus,
                            date: moment().format(constants.DATETIME_FORMAT),
                        },
                    }),
                },
            ]);
            console.log(
                `patchResponse for device ${auftragDetail712.auftragDetailID}, device ${deviceID}`,
                patchResponse
            );

            return zfaJobStatus;
        }
    },

    async executeZfaJobRead(device) {
        const response = await zfaAPI.post(device.deviceID);
        return {
            deviceID: device.deviceID,
            factoryNo: device.factoryNo,
            date: moment().format(constants.DATETIME_FORMAT),
            ...response?.data,
        };
    },

    async executeZfaJobReadSaveToStep712(auftragID, devicePoolID, device) {
        const step712 = await auftragDetailsAPI.getByOrderIdAndStep(auftragID, "7-1-2", devicePoolID, device.deviceID);

        let zfaResponse;
        try {
            zfaResponse = await this.executeZfaJobRead(device);
        } catch (error) {
            zfaResponse = null;
        }

        const status =
            zfaResponse?.code >= 0
                ? constants.auftragDetailStatus.DONE_CONDITION_1
                : constants.auftragDetailStatus.ERROR;

        await auftragDetailsAPI.patchValueAndStatusOnDiff(step712.auftragDetailID, step712.value, zfaResponse, status);
        return zfaResponse;
    },
};
