<template>
    <Box :headerText="actAuftragDetail?.action?.title" :isLoading="!actAuftragDetail || !order">
        <template v-if="actAuftragDetail && order">
            <div class="mb-3">
                <ExecButtonPrevious @clickPrevious="onClickPrevious" :navigateToStep="navigateToStepPrevious" />
                <ExecButtonNext class="float-right" @clickNext="onClickNext" :navigateToStep="navigateToStepNext" />
            </div>
            <div>
                <b-spinner v-if="!device" />
                <div v-else>
                    <exec-meter-info
                        class="mb-2"
                        :mainDevice="device"
                        :order="order"
                        :devicePool="devicePool"
                        :category="'Verbinden mit Smartmanager'"
                    />
                    <div>
                        Stromzähler in MSP gefunden? <span v-if="smetMsp">Ja</span>
                        <span v-else class="font-weight-bold">Nein</span>
                    </div>
                    <p class="mb-1 mt-2">
                        Bitte verbinden Sie jetzt den Stromzähler an den RS485 Bus des Smart Managers.
                    </p>
                    <div class="mt-2">An welchen Smart Manager wurde der Zähler angeschlossen?</div>
                    <b-form-select v-model="smanID" :options="deviceSmanOpts" />
                    <div class="mt-2">Schnittstelle:</div>
                    <b-form-select v-model="devicePortId" :options="devicePortOpts" />
                    <div class="mt-2">Baudrate:</div>
                    <b-form-select v-model="deviceBaudRateId" :options="deviceBaudRateOpts" />
                    <div class="mt-2">LLS-Passwort:</div>
                    <b-form-checkbox v-model="useDefaultPassword">
                        Standard-Passwort des Zählers/Zählertyps verwenden
                    </b-form-checkbox>
                    <b-form-input v-model="devicePassword" :disabled="useDefaultPassword" />
                    <div class="mt-2">
                        BusAddress ({{ device.busAddressAlg }}):
                        <code>{{ busAddressCalculated }}</code>
                        <br />
                        Medium:
                        <span v-if="mediumObject">
                            <code>{{ mediumObject.name }}</code>
                        </span>
                        <span v-else>Nicht gefunden in MSP</span><br />
                        Zähler Typ: {{ deviceType }}
                        <span v-if="deviceType">
                            <code>{{ deviceType }}</code>
                        </span>
                        <span v-else>
                            <code>{{ device.deviceType }}</code> <br />
                            <b style="background-color: #ff463c;"
                                >Zählertyp nicht in MSP gefunden oder Keyfile noch nicht in MSP eingelesen!</b
                            >
                            - "Erledigt" deaktiviert
                        </span>
                    </div>
                </div>
            </div>
            <ExecSectionErledigt
                :isBusy="isLoading"
                :isExecutable="actAuftragDetail.isExecutable"
                :isExecutableDetails="actAuftragDetail.isExecutableDetails"
                :preconditionsLocalDetails="compPreconditionsLocalDetails"
                @click-erledigt="onClickErledigt"
            />
        </template>
    </Box>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import constants from "@/constants/constants";
import { isEmptyArray } from "@/utils/CommonUtils";

import ExecButtonNext from "@/components/execution/ExecButtonNext";
import ExecMeterInfo from "@/components/execution/ExecMeterInfo";
import ExecButtonPrevious from "@/components/execution/ExecButtonPrevious";
import ExecSectionErledigt from "@/components/execution/ExecSectionErledigt.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import mspAPI from "@/services/api/msp.api";
import devicesAPI from "@/services/api/devices.api";
import devicesInfosAPI from "@/services/api/deviceInfos.api";
import keyValueOptionsAPI from "@/services/api/keyValueOptionsScd.api";
import smartpowerAPI from "@/services/api/smartpower.api";

export default {
    name: "Step_7_2_4",
    components: {
        ExecButtonNext,
        ExecButtonPrevious,
        ExecMeterInfo,
        ExecSectionErledigt,
    },
    props: {
        order: {
            type: Object,
            default: function() {
                return null;
            },
        },
        devicePool: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            ...mapGetters({
                storeActAuftragDetail: "execution/storeActAuftragDetail",
            }),
            auftragDetail720: null, // Ausbau SMET
            auftragDetail721: null, // Ausbau Zähler
            navigateToStepNext: null,
            navigateToStepPrevious: null,
            smetMsp: null, // SMET data from MSP
            // Data to send to MSP
            device: null, // device.factoryNo to MSP
            deviceBaudRateId: null,
            smanID: null,
            devicePortId: null,
            mediumObject: null, // pass mediumObject.mediumId to MSP
            deviceType: null,
            devicePassword: null,
            useDefaultPassword: true,
            busAddressCalculated: null,
            busAddressValid: false,
            // END Data to send to MSP
            previousValue: {
                smanID: null,
                devicePortId: null,
                deviceBaudRateId: null,
                devicePassword: null,
            },
            deviceBaudRateOpts: [],
            devicePortOpts: [],
            deviceSmanOpts: [],
            isLoading: false,
        };
    },
    computed: {
        actAuftragDetail() {
            return this.storeActAuftragDetail();
        },
        compErledigtIsDisabled() {
            return (
                !this.actAuftragDetail.isExecutable ||
                !this.smanID ||
                !this.devicePortId ||
                !this.deviceBaudRateId ||
                !this.deviceType ||
                !this.busAddressValid
            );
        },
        compPreconditionsLocalDetails() {
            const preconditionsLocalDetails = [];

            if (this.compErledigtIsDisabled) {
                preconditionsLocalDetails.push({ status: "OPEN", message: "Lokale Vorbedingungen nicht erfüllt." });
            } else {
                preconditionsLocalDetails.push({ status: "FULFILLED", message: "Lokale Vorbedingungen erfüllt." });
            }

            return preconditionsLocalDetails;
        },
        deviceID() {
            return this.actAuftragDetail?.deviceID;
        },
        compIsMeterStays() {
            // Return true if SMET/Zähler stays
            return this.auftragDetail720?.status === 203 || this.auftragDetail721?.status === 203;
        },
    },
    async mounted() {
        this.isLoading = true;
        const pathSplit = this.$route.fullPath.split("/");
        const step = pathSplit[pathSplit.length - 1];
        await auftragDetailsAPI
            .getByOrderIdAndStep(
                this.$route.params.orderId,
                step,
                this.$route.params.devicePoolId,
                null,
                this.$route.params.auftragItemId
            )
            .then((res) => {
                this.setActAuftragDetail(res);
            });

        await keyValueOptionsAPI.get({ category: "device", subCategory: "baudrate" }).then((resp) => {
            const deviceBaudRates = [{ value: null, text: "Bitte Baudrate auswählen" }];
            resp.data.forEach((s) => {
                deviceBaudRates.push({
                    value: s.externalFieldName,
                    text: s.text,
                });
            });
            this.deviceBaudRateOpts = deviceBaudRates;
        });
        await keyValueOptionsAPI.get({ category: "device", subCategory: "portid" }).then((resp) => {
            const deviceports = [{ value: null, text: "Bitte einen Port wählen" }];
            resp.data.forEach((s) => {
                deviceports.push({ value: s.externalFieldName, text: s.text });
            });
            this.devicePortOpts = deviceports;
        });

        // todo: mettere fromDate__gte 2050
        const deviceSmans = await devicesAPI.get({
            devicePoolID: this.$route.params.devicePoolId,
            type: "Steuereinheit",
            deviceSuffix: "Smart Manager",
            includeDeviceInfos: false,
            fromDate__lte: "2050-12-31",
            toDate__gte: "2050-12-31",
        });
        this.deviceSmanOpts.push({ value: null, text: "Bitte einen SmartManager wählen", externalFieldName: null });
        deviceSmans.forEach((s) => {
            this.deviceSmanOpts.push({
                value: s.factoryNo,
                text: s.factoryNo ?? `SMAN ${s.deviceID} has no FactoryNo`,
            });
        });
        this.device = (await devicesAPI.getSingle(this.actAuftragDetail.deviceID, { includeDeviceInfos: true })).data;
        console.log(`Meter has a default password: ${this.device.hasDefaultPassword}`);
        // Load SmanID
        const smanDevInfo = this.device.deviceInfos.find((di) => di.name === "RS485.SMAN");
        this.smanID = this.previousValue.smanID = smanDevInfo ? smanDevInfo.value : null;
        // Load devicePortId
        const devicePortIdDevInfo = this.device.deviceInfos.find((di) => di.name === "RS485.DevicePort");
        this.devicePortId = this.previousValue.devicePortId = devicePortIdDevInfo ? devicePortIdDevInfo.value : null;
        // Load deviceBaudRateId
        const deviceBaudRateIdDevInfo = this.device.deviceInfos.find((di) => di.name === "RS485.BaudRate");
        this.deviceBaudRateId = this.previousValue.deviceBaudRateId = deviceBaudRateIdDevInfo
            ? deviceBaudRateIdDevInfo.value
            : null;
        // IF deviceBaudrate is not set - prepopulate Baudrate with default value (if any)
        if (!this.deviceBaudRateId) {
            if (this.deviceBaudRateOpts.find((dbr) => dbr.text === this.device.defaultBaudrate)) {
                this.deviceBaudRateId = this.deviceBaudRateOpts.find(
                    (dbr) => dbr.text === this.device.defaultBaudrate
                )?.value;
            }
        }
        // Load custom devicePassword
        const devicePasswordDevInfo = this.device.deviceInfos.find((di) => di.name === "RS485.Password");
        this.devicePassword = this.previousValue.devicePassword = devicePasswordDevInfo
            ? devicePasswordDevInfo.value
            : null;

        if (this.device.factoryNo) {
            try {
                this.smetMsp = (await smartpowerAPI.getSMET(this.device.factoryNo)).data;
                // Copy existing MSP values to deviceType and mediumId
                this.mediumObject = (await smartpowerAPI.getMediumByID(this.smetMsp.mediumId)).data;
                this.deviceType = this.smetMsp.deviceType;
            } catch (err) {
                // SMET not found in MSP
                if (err.response.status === 404) {
                    this.smetMsp = null;
                    const mediums = (await smartpowerAPI.getMediums({ name: "Electricity" })).data;
                    if (mediums.length > 0) {
                        this.mediumObject = mediums[0];
                    }
                    const deviceTypeObject = (await smartpowerAPI.getDeviceTypeByAlias(this.device.deviceType)).data;
                    this.deviceType = deviceTypeObject?.deviceType;
                } else {
                    this.$bvToast.toast(`Fehler beim Aufruf von MSP für SMET ${this.device.factoryNo}`, {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    });
                }
                console.warn(`FactoryNo ${this.device.factoryNo} not found on MSP`);
            }
            // Check if mediumId and deviceType were found in MSP (in some way)
            if (!this.mediumObject) {
                this.$bvToast.toast(
                    `MediumId ${this.device.factoryNo} wurde nicht in MSP für SMET ${this.device.factoryNo} gefunden`,
                    {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
            }
            if (!this.deviceType) {
                this.$bvToast.toast(`DeviceType ${this.device.deviceType} wurde nicht in MSP gefunden.`, {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
            }
        }

        // Load Ausbau Auftrag details to know if meter stays
        this.auftragDetail720 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            "7-2-0",
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId,
            false
        );
        this.auftragDetail720 = isEmptyArray(this.auftragDetail720) ? null : this.auftragDetail720; // Avoid storing []
        this.auftragDetail721 = await auftragDetailsAPI.getByOrderIdAndStep(
            this.$route.params.orderId,
            "7-2-1",
            this.$route.params.devicePoolId,
            null,
            this.$route.params.auftragItemId,
            false
        );
        this.auftragDetail721 = isEmptyArray(this.auftragDetail721) ? null : this.auftragDetail721; // Avoid storing []
        // Calculate busAddress
        this.busAddressCalculated = await this.calculateBusAddress(this.$route.params.orderId, this.device.deviceID);
        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            setActAuftragDetail: "execution/setActAuftragDetail",
        }),
        /**
         * Call and set auftragDetaiil.status:
         * 202 - meter.requireWandler=false AND meter connected to RS485
         * 203 - meter.requireWandler=true AND meter connected to RS485 (AND wandler is NOT in ISE)
         * 204 - meter.requireWandler=true AND meter connected to RS485 (AND and wandler is in ISE)
         * 500 - connection not possible
         * Note: for the time being we assume that wandler does not exist in ISE (we have no case of wandler existing so far)
         * @returns {Promise<void>}
         */
        async onClickErledigt() {
            const meterConfig = {
                meterId: this.device.factoryNo,
                initialBaudRate: this.deviceBaudRateId,
                portId: this.devicePortId,
                medium: this.mediumObject?.id,
                deviceDriver: this.deviceType,
                meterType: this.deviceType,
                busAddress: this.busAddressCalculated,
                password: this.devicePassword ?? null,
                useDefaultPassword: this.useDefaultPassword,
            };
            const mspResponse = await mspAPI.postSmartmeterBySmanID(this.smanID, meterConfig);
            const auftragStatus = this.calculateStatus(this.device.requireWandler, mspResponse);
            console.log("mspResponse", mspResponse);
            const payloadAuftragDetail = [
                {
                    op: "replace",
                    path: "/status",
                    value: auftragStatus,
                },
            ];
            await auftragDetailsAPI.patch(this.actAuftragDetail.auftragDetailID, payloadAuftragDetail);
            await this.save();
            // TODO: per ora mandiamo sempre al menu. Quando sara' pronto il Wandler Process:
            // se wandler: 7-3-1
            // altrimenti: menu 7
            this.navigateToStepNext = this.actAuftragDetail.stepNextFalse;
        },
        async save() {
            const basePayload = {
                deviceID: this.deviceID,
                dataType: "Alphanumerisch",
            }; // populate later: name, value
            if (this.smanID !== this.previousValue.smanID) {
                if (this.previousValue.smanID) {
                    await devicesInfosAPI.delete(
                        this.device.deviceInfos.find((di) => di.name === "RS485.SMAN").deviceInfoID
                    );
                }
                await devicesInfosAPI.post({ ...basePayload, name: "RS485.SMAN", value: this.smanID }); // SMAN
            }
            if (this.devicePortId !== this.previousValue.devicePortId) {
                if (this.previousValue.devicePortId) {
                    await devicesInfosAPI.delete(
                        this.device.deviceInfos.find((di) => di.name === "RS485.DevicePort").deviceInfoID
                    );
                }
                await devicesInfosAPI.post({ ...basePayload, name: "RS485.DevicePort", value: this.devicePortId }); // devicePortId
            }
            if (this.deviceBaudRateId !== this.previousValue.deviceBaudRateId) {
                if (this.previousValue.deviceBaudRateId) {
                    await devicesInfosAPI.delete(
                        this.device.deviceInfos.find((di) => di.name === "RS485.BaudRate").deviceInfoID
                    );
                }
                await devicesInfosAPI.post({ ...basePayload, name: "RS485.BaudRate", value: this.deviceBaudRateId }); // deviceBaudRateId
            }
            if (this.devicePassword !== this.previousValue.devicePassword) {
                if (this.previousValue.devicePassword) {
                    await devicesInfosAPI.delete(
                        this.device.deviceInfos.find((di) => di.name === "RS485.Password").deviceInfoID
                    );
                }
                await devicesInfosAPI.post({ ...basePayload, name: "RS485.Password", value: this.devicePassword }); // devicePassword
            }
        },
        calculateStatus(requireWandler, mspResponse) {
            const isMeterConnectedToMSP = mspResponse === true; // It seems the API return true if meter is connected to MSP - TODO check @Giorgio
            if (requireWandler) {
                return constants.auftragDetailStatus.DONE_CONDITION_2; // TODO: check other condition: 04 - meter.requireWandler=true AND meter connected to RS485 (AND and wandler is in ISE)
            } else {
                return isMeterConnectedToMSP
                    ? constants.auftragDetailStatus.DONE_CONDITION_1
                    : constants.auftragDetailStatus.BLOCKED;
            }
        },
        onClickNext() {
            // if wandler: 7-3-1 (future implementation)
            // otherwise: menu 7
            this.navigateToStepNext = this.actAuftragDetail.stepNextFalse;
        },
        onClickPrevious(step) {
            this.navigateToStepPrevious = step;
        },
        async calculateBusAddress(auftragId, deviceId) {
            const response = (await devicesAPI.validateDeviceNewBusAddress(auftragId, deviceId)).data;
            if (response.isValid) {
                this.busAddressValid = true;
                return response.calculatedBusAddress;
            } else if (!response.isValid && response.conflictingDevice) {
                this.busAddressValid = false;
                this.$bvToast.toast(
                    `Achtung, dieser Zähler erhält nach Mod50 die gleiche Bus Adresse ${response.calculatedBusAddress} wie der Zähler mit der WerkNr ${response.conflictingDevice?.factoryNo}.
                    Bitte verwenden Sie einen Zähler der nach Mod50 an diesem Gerätestandort eine eindeutige Bus Adresse hat.`,
                    {
                        title: "Fehler",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        noAutoHide: true,
                        appendToast: true,
                    }
                );
                return response.calculatedBusAddress;
            } else {
                this.busAddressValid = false;
                this.$bvToast.toast(`Fehler beim Berechnen der Bus Adresse für den Zähler ${deviceId}.`, {
                    title: "Fehler",
                    variant: "danger",
                    toaster: "b-toaster-bottom-right",
                    noAutoHide: true,
                    appendToast: true,
                });
                return "Error";
            }
        },
    },
};
</script>
